import { useEffect } from 'react'
import photo_jacuzzi from './image1.webp'
import photo_exterieur from './image2.webp'
import photo_accueil from './image3.webp'
import './Accueil.css'

function Accueil() {
  useEffect(() => {
    document.title = "Accueil - Azoya SPA Vaux";
  }, [])
  return (
    <>
      <div className="slide-container">
        <div className="slide slide-left">
          <div className="slide-text-content">
            <h1>Un <em>espace de détente</em> à <em>10 minutes</em> d'Auxerre</h1>
            <p>
              Profitez de différentes façons de prendre soin de votre corps et de vous relaxer au sein de notre structure.<br />
              Tout cela, à seulement 10 minutes d'Auxerre !
            </p>
          </div>
          <div className="slide-image-box">
            <img src={photo_exterieur} alt="photo_exterieur" />
          </div>
        </div>
        <div className="slide slide-center">
          <div className="slide-text-content">
            <h1><em>Jacuzzi et Hammam privatif</em> pour profiter seul ou entre amis</h1>
            <p>
              L'espace privatif est idéal pour se relaxer et se recentrer sur soi-même. <br />
              C'est également le meilleur moyen de profiter avec vos amis/proches d'un grand moment de détente en hiver comme en été avec sa terrasse privative !
            </p>
          </div>
          <div className="slide-image-box">
            <img src={photo_jacuzzi} alt="photo_jacuzzi" />
          </div>
        </div>
        <div className="slide slide-right">
          <div className="slide-text-content">
            <h1>Des produits <em>Baïja</em> dédiés à prendre soin de vous</h1>
            <p>
              Vous pourrez trouver à l'accueil tous les produits nécessaires pour prendre soin de vous à la maison (gommages, crèmes, masques, huiles, gels douche, etc...).<br />
              Mais aussi des produits doux et relaxant tel que des bombes de bain, des bougies, des bouquets parfumés et bien d'autres.<br />
              N'hésitez pas à prendre conseil auprès de votre esthéticienne.
            </p>
          </div>
          <div className="slide-image-box">
            <img src={photo_accueil} alt="photo_accueil" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Accueil;