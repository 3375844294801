import './Header.css'
import Logo from '../azoya_logo_vaux.svg'
import { FaBars, FaTimes } from "react-icons/fa"
import { useRef, useEffect } from "react"
import { Link } from 'react-router-dom';

function Header() {
  // Reference to navbar.
  const navRef = useRef();

  // Toggle navbar by adding/removing a class style.
  const toggleNavbar = () => {
    if (window.innerWidth <= 1120) {
      navRef.current.classList.toggle("responsive-nav");
    }
  }
  
  // We use useEffect function with no dependency to call only one time the callback function.
  // Maybe that can be better ...
  useEffect(() => {

    // Handler for resize event.
    const changeWidth = () => {
      // Remove responsive class style when screen width > 1024px.
      if (window.innerWidth > 1120) {
        navRef.current.classList.remove("responsive-nav");
      }
    };

    // Listen to resize event.
    window.addEventListener('resize', changeWidth);

    // Cleanup function to remove resize event listener.
    return (() => {
      window.removeEventListener('resize', changeWidth);
    });

  }, []);

  return (
    <header className="Header">
      <div>
        <h1><a href="https://www.azoya-spa.com">Vous êtes à AzoyaSPA Vaux, cliquez pour changer.</a></h1>
      </div>
      <div>
        <Link to="/accueil"><img src={Logo} alt="Logo" /></Link>
        <nav ref={navRef}>
            <Link to='/accueil' onClick={toggleNavbar}>Accueil</Link>
            <Link to='/mon-compte' onClick={toggleNavbar}>Mon compte Planity</Link>
            <Link to='/services' onClick={toggleNavbar}>Services</Link>
            <Link to='/offres-abonnement' onClick={toggleNavbar}>Offres/Abonnement</Link>
            {/* <a href='/team'>Team</a> */}
            <button className='nav-btn nav-close-btn' onClick={toggleNavbar}>
              <FaTimes />
            </button>
        </nav>
        <Link className="button" to="/reserver-en-ligne">
            <p>Réserver en ligne</p>
        </Link>
        <button className='nav-btn' onClick={toggleNavbar}>
          <FaBars />
        </button>
      </div>
    </header>
    );
}

export default Header;