import Logo from '../logo.png'
import './Footer.css'

function Footer() {
  return (
    <div className="Footer">
      <div className='box-left'>
        <h1>Contact</h1>
        <p><a target={`_blank`} href="http://maps.google.com/?q=1bis, Rue Saint-Vincent 89290 Auxerre">1bis, Rue Saint-Vincent 89290 Auxerre</a></p>
        <p><a href="tel:+33679333364">06 79 33 33 64</a></p>
        <p><a href="mailto:contact-vaux@azoya-spa.com">contact-vaux@azoya-spa.com</a></p>
      </div>
      <div className='box-center'>
        <img src={Logo} alt="Logo" />
      </div>
      <div className='box-right'>
        <h1>Horaires</h1>
        <p>Ouvert 5/7 jours</p>
        <p>Lundi - Mardi - Mercredi - Vendredi - Samedi</p>
        <p>Jeudi - Dimanche : Fermé</p>
      </div>
    </div>
  );
}

export default Footer;