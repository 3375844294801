import React, { useEffect } from 'react'
import './PlanityAccount.css'

export default function PlanityAccount() {

    useEffect(() => {
        const ref = document.getElementById('planityContainer');
        // A supprimer lors de la création d'une page dédiée.
        document.title = "Mon compte - Azoya SPA Vaux";

        window.planity = {
            key: '-MmNxEmEfwgR5sqRSHw2',
            primaryColor: '#87D9D9',
            options: {
                headerWidth: '100px'
            },
            accountContainer: ref
        };

        const script = document.createElement('script');
        script.src = "https://d2skjte8udjqxw.cloudfront.net/widget/production/polyfills.latest.js";
        script.async = true;
        const script2 = document.createElement('script');
        script2.src = "https://d2skjte8udjqxw.cloudfront.net/widget/production/app.latest.js";
        script2.async = true;

        ref.appendChild(script);
        ref.appendChild(script2);

    })

    return (
        <div className='planity'>
            <div id='planityContainer'></div>
        </div>
    )
}
