import React, { useEffect } from 'react'
import "./PlanityAppointment.css"
import { redirect } from 'react-router-dom';

export default function PlanityAppointment({ whitelist }) {

    useEffect(() => {
        const ref = document.getElementById('planityContainer');

        window.planity = {
            key: '-MmNxEmEfwgR5sqRSHw2',
            primaryColor: '#87D9D9',
            appointmentContainer: ref,
            options: {
                serviceSetsWhitelist: whitelist,
                servicesNotCollapsed: false,
                headerWidth : '100px',
                appointmentRedirect: () => {
                    redirect("/mon-compte")
                }
            }
        };

        const script = document.createElement('script');
        script.src = "https://d2skjte8udjqxw.cloudfront.net/widget/production/polyfills.latest.js";
        script.async = true;
        const script2 = document.createElement('script');
        script2.src = "https://d2skjte8udjqxw.cloudfront.net/widget/production/app.latest.js";
        script2.async = true;

        ref.appendChild(script);
        ref.appendChild(script2);
    });


    return (
        <div className='planity'>
            <div id='planityContainer'></div>
        </div>
    )
}
