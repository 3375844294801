import { React, useEffect, useState } from 'react';
import './ReserverEnLigne.css'
import Illustration from "./Illustration.svg"
import PlanityAppointment from '../../components/planity/Appointment/PlanityAppointment';

function ReserverEnLigne() {

  useEffect(() => {
    document.title = "Reserver en ligne - Azoya SPA Vaux";
  }, [])

  const [find, setFind] = useState(false);

  const activeFind = () => {
    const form = document.getElementById('form-choices');
    form.style.display = 'none';
    setFind(true);
  }

  const deactiveFind = () => {
    const form = document.getElementById('form-choices');
    form.style = undefined
    setFind(false);
  }

  // Whitelist state and methods
  const [whitelist, setWhitelist] = useState([]);

  const addToWhitelist = (id) => setWhitelist([...whitelist, ...id]);
  const removeFromWhitelist = (id) => setWhitelist(whitelist.filter(e => !id.includes(e)));

  const handleChoiceCheckbox = (e) => {
    e.target.checked ? addToWhitelist(e.target.value.split(';')) : removeFromWhitelist(e.target.value.split(';'))
  }

  const handleFindButton = (e) => {
    e.preventDefault();
    activeFind();
  }

  const handleResetButton = (e) => {
    e.preventDefault();
    deactiveFind();
  }


  return (
    <div className="ReserverEnLigne" id='ReserverEnLigne'>

      <div className="slide">
        <img src={Illustration} alt="Illustration" />
        <div className="text-content">
          <h1>Reservez <em>en ligne</em> via notre partenaire Planity</h1>
          <p>Pour réserver il vous suffit de commander via notre application planity intégrée plus bas. Vous serez ammené à verser un accompte de 20% pour vos réservations internet. Si vous le souhaitez vous pourrez également régler vos prestations directement à la réservation.</p>
        </div>
      </div>


      {/* <div className="documentation">
        <h1>Vous ne connaissez pas encore nos prestations ?</h1>
        <p>
          Vous pouvez nous contacter au <a href="tel:+33679333364">06 79 33 33 64</a> ou 
          sur <a href="mailto:contact@azoya-spa.com">contact@azoya-spa.com</a> et nous répondrons à 
          toutes vos questions. Nous prévoyons de réaliser une page dédié à la description de nos services.
        </p>
      </div> */}

      <form id='form-choices'>
        <div className="head">
          <h1>Qu'est-ce qui vous intéresse ?</h1>
          <p>
            Sélectionnez les catégories qui vous intéresse puis cliquez sur "Afficher ma sélection" pour choisir
            vos prestations.<br />
          </p>
        </div>

        <div className="choices">
          <div className="choice">
            <input 
            type="checkbox" 
            name="spa-hammam" 
            id="spa-hammam" 
            onChange={handleChoiceCheckbox}
            value="-NTIsrwCcwqjzOk5MgtJ;-NTIsw52f4cdxGUWlUFs;-NTIszUjcyiKj7vw69vz;-NTIt4Ou0SzKAVOXgBwz;-NTIt9omYXtwQLa6LTHa" />
            <label htmlFor="spa-hammam">SPA - Hammam</label>
          </div>
          <div className="choice">
            <input 
            type="checkbox" 
            name="spa-mini-kids" 
            id="spa-mini-kids" 
            onChange={handleChoiceCheckbox}
            value="-NTIxPE9gZL_xWYZNWbB" />
            <label htmlFor="spa-mini-kids">SPA Mini Kids</label>
          </div>
          <div className="choice">
            <input 
            type="checkbox" 
            name="amincissement" 
            id="amincissement"
            onChange={handleChoiceCheckbox}
            value="-NTIyA4WOkezx0W35CnQ;-NTIyES7yXduJOYhKesA;-NTIyGs2NrbRGZUTClRL;-NTIyKSppOSdkCoEVWsh" />
            <label htmlFor="amincissement">Amincissement</label>
          </div>
          <div className="choice">
            <input 
            type="checkbox" 
            name="soins-corps" 
            id="soins-corps"
            onChange={handleChoiceCheckbox}
            value="-NTJ-7RJN1WdsSDJgKCi;-NTJ32MQ2SHQ7yxr1gtX;-NTJ3SwIAcAk3bS9zc_7" />
            <label htmlFor="soins-corps">Soins Corps</label>
          </div>
          <div className="choice">
            <input 
            type="checkbox" 
            name="rituels" 
            id="rituels"
            onChange={handleChoiceCheckbox}
            value="-NTJ3kzBj7tmyqqysCCp;-NTJ3qDZIk-W0MCE_A2r;-NTJ467aNr_CppT_7ylS;-NTJ4eahml7XzQFTM_cL" />
            <label htmlFor="rituels">Rituels</label>
          </div>
          <div className="choice">
            <input 
            type="checkbox" 
            name="soins-visage-baija" 
            id="soins-visage-baija"
            onChange={handleChoiceCheckbox}
            value="-NTJ4uu_EjyIMJO6cZyL;-NTJ5F93AbQFo4VsOcdp" />
            <label htmlFor="soins-visage-baija">Soins Visage Baïja</label>
          </div>
          <div className="choice">
            <input 
            type="checkbox" 
            name="epilations" 
            id="epilations"
            onChange={handleChoiceCheckbox}
            value="-NTJ5YRVPog_b_g1OSoj;-NTJ65pK_Zvo0sLNVE8z;-NTJ6Tai-97PbMLXVPit;-NTJ6p85END6oB3yYrCI;-NTJ7HJdtG9rY1dOu3fv;-NTJ7bTn4gubJl4wyL4w" />
            <label htmlFor="epilations">Épilations</label>
          </div>
          <div className="choice">
            <input 
            type="checkbox" 
            name="regard" 
            id="regard"
            onChange={handleChoiceCheckbox}
            value="-NTJ7q7-3d61qJDFcwMT;-NTJ7xy1cLjKu1B_AHN1" />
            <label htmlFor="regard">Regard</label>
          </div>
          <div className="choice">
            <input 
            type="checkbox" 
            name="lumiere-pulsee" 
            id="lumiere-pulsee"
            onChange={handleChoiceCheckbox}
            value="-NTJ8GDFrHKaFo5usa5p;-NTJ8vrYiVSq8ETIgGKr;-NTJ9QQ99ANNsTDg9iXj;-NTJ9paja8AfBT_0mJCv;-NTJAKuUygpla9VMesqh;-NTJAgo0yFpMsEBxCnkU" />
            <label htmlFor="lumiere-pulsee">Lumière Pulsée</label> 
          </div>
          <div className="choice">
            <input 
            type="checkbox" 
            name="ongles" 
            id="ongles"
            onChange={handleChoiceCheckbox}
            value="-NTJB5GhxXIXac2tQzQi;-NTJB7AXDDaYM8OYGtM7;-NTJB8c6bh9yCAmYjOFj;-NTJBBVk0vpbd1_NCaVY;-NTJBLoq4nGfpZ-kDnjH" />
            <label htmlFor="ongles">Mains - Pieds</label> 
          </div>
        </div>
        <div className="buttons">
          <button onClick={handleFindButton}>Afficher ma sélection</button>
        </div>
      </form>

      {find && 
      <>
        <form id='form-reset'>
          <div className="buttons">
            <button onClick={handleResetButton}>Modifier ma selection</button>
          </div>
        </form>
      </>
      }

      <div className='call-to-gift'>
        Vous souhaitez offrir ? <a href='/offrir' target='_blank'>Cliquez ici</a>
      </div>

     {find && <PlanityAppointment whitelist={[...whitelist]}/>} 
    </div>
  );
}

export default ReserverEnLigne;