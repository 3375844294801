import { React, useEffect, useState } from 'react';
import './Services.css'
import Illustration from "./Illustration.svg"
import TablePrice from '../../components/table/price/TablePrice';

function Services() {
  const servicesArr = require("../services.json");

  const [toggle, setToggle] = useState(false);
  const [service, setService] = useState(null);

  useEffect(() => {
    document.title = "Services - Azoya SPA Vaux";
  }, [])

  return (
    <div className="Services">
      <div className="slide">
        <img src={Illustration} alt="illustration" />
        <h1>Notre panel de <em>services</em> est mis à disposition pour <em>satisfaire</em> chaque <em>client</em></h1>
      </div>
      <div className="services-container">
        {servicesArr.map(serviceElement => {
          return (
            <a href='#service'>
              <div key={serviceElement.title} className="service-button" onClick={() => {
                if (service != null && service.title === serviceElement.title && toggle) {
                  setToggle(!toggle);
                } else if (service != null && service.title !== serviceElement.title && toggle) {
                  setService(serviceElement);
                } else {
                  setService(serviceElement);
                  setToggle(!toggle);
                }
                }}>
                <h1>{serviceElement.title}</h1>
              </div>
            </a>
          );
        })}
      </div>
      <div id="service"></div>
      {toggle && <TablePrice service={service}/>}
    </div>
  );
}

export default Services;